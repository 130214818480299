import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import "./permission";
import {getDicts} from "@/api/system/dict/data";

import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import zhCn from "element-plus/es/locale/lang/zh-cn";

// 修改分页器默认文字
zhCn.el.pagination.total = "共 {total} 条";
zhCn.el.pagination.goto = "跳至";
zhCn.el.pagination.pagesize = "条/页";
zhCn.el.pagination.pageClassifier = "页";

const app = createApp(App);
// 将 axios 挂载到 Vue 实例的原型上
app.config.globalProperties.$axios = axios;
app.config.globalProperties.getDicts = getDicts;

app.directive("permission", {
    mounted(el, binding) {
        // 按钮所需权限码
        const btnCodes = binding.value;
        const permission = hasPermission(btnCodes);
        // 无权限
        if (!permission) {
            el.parentNode?.removeChild(el);
        }
    },
});
//获取用户信息
// store.dispatch("GetInfo").then(() => {});

function hasPermission(requiredPermissions) {
    // 当前用户的权限存储在变量 userPermissions 中
    const userPermissions = localStorage.getItem("permissionsBtn");
    // 检查用户权限是否包含所需权限
    if (userPermissions) {
        for (const permission of requiredPermissions) {
            if (!userPermissions.includes(permission)) {
                return false;
            }
        }
        // 所有所需权限都存在于用户权限中
        return true;
    }
}

import "element-plus/dist/index.css";
app.use(store).use(router).use(ElementPlus, {locale: zhCn}).mount("#app");
