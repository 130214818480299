import { createStore } from 'vuex'
import permission from './modules/permission'
import dict from './modules/dict'
import user from './modules/user'
export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    permission,
    dict,
    user
  }
})
