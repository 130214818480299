import {getRouters} from "@/api/menu";
import Layout from "@/layout/index";
import router, {routeConfig} from "@/router";
// 匹配views里面所有的.vue文件

const permission = {
    state: {
        routes: [],
        addRoutes: [],
    },
    mutations: {
        SET_ROUTES: (state, routes) => {
            state.addRoutes = routes;
            state.routes = routes;
        },
    },
    actions: {
        // 生成路由
        GenerateRoutes({commit}) {
            return new Promise((resolve) => {
                // 向后端请求路由数据（菜单）
                getRouters()
                .then((res) => {
                    const rdata = JSON.parse(JSON.stringify(res.data.data));
                    const rewriteRoutes = filterAsyncRouter(rdata, false, true);
                    // rewriteRoutes.forEach(function(item){
                    //     if(routeConfig[item.path] && routeConfig[item.path].children){
                    //         if(!item.children){
                    //             item.children = []
                    //         }
                    //         item.children.push(...routeConfig[item.path].children)
                    //     }
                    // })
                    rewriteRoutes.unshift({
                        path: "/",
                        redirect: rewriteRoutes[0].path,
                        meta: {},
                    });
                    commit("SET_ROUTES", rewriteRoutes);
                    resolve(rewriteRoutes);
                })
                .catch((err) => {
                    console.log(err);
                });
            });
        },
    },
};

// // 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap, lastRouter = false, type = false) {
    return asyncRouterMap.filter((route) => {
        // 将 ruoyi 后端原有耦合前端的逻辑，迁移到此处
        // 处理 meta 属性
        route.meta = {
            title: route.name,
            icon: route.icon,
            noCache: !route.keepAlive,
        };
        route.hidden = !route.visible;

        // 处理 component 属性
        if (route.parentId === 0) {
            // 父节点
            if (!route.children?.length) {
                const {component, icon, keepAlive, name, visible, meta} = route;
                route.children = [{component: loadView(component), icon, keepAlive, name, path: "", visible, meta}];
                route.isFilterChildren = false;
            }
            route.component = Layout;
        } else {
            // 根节点
            route.component = loadView(route.component);
        }

        if (type && route.children) {
            route.children = filterChildren(route.children);
        }
        if (route.children != null && route.children?.length && route.isFilterChildren !== false) {
            route.children = filterAsyncRouter(route.children, route, type);
        } else if (route.isFilterChildren !== false) {
            delete route["children"];
        }
        return true;
    });
}

function filterChildren(childrenMap, lastRouter = false) {
    let children = [];
    childrenMap.forEach((el, index) => {
        if (el.children && el.children.length) {
            if (!el.component && !lastRouter) {
                el.children.forEach((c) => {
                    c.path = el.path + "/" + c.path;
                    if (c.children && c.children.length) {
                        children = children.concat(filterChildren(c.children, c));
                        return;
                    }
                    children.push(c);
                });
                return;
            }
        }
        if (lastRouter) {
            el.path = lastRouter.path + "/" + el.path;
        }
        children = children.concat(el);
    });
    return children;
}

export const loadView = (view) => {
    // 路由懒加载
    return () => import(`@/views/${view}`);
};

export default permission;
