<template>
  <router-view />
</template>
<script setup>
const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
}

</script>

<style lang="scss">
@import '@/assets/css/common.scss';

* {
  margin: 0;
  padding: 0;
}
.app-container{
  padding-bottom: 50px;
}
.fff {
  background: #fff;
}

// body {
//   min-width: 1920px;
//   background: #F7F9FD;
// }

.el-main {
  padding: 0 !important;
}

.el-container {
  flex-direction: column !important;
}

.el-form--inline .el-form-item {
  align-items: center;
}

.el-form-item__label {

  font-weight: 500 !important;
  font-size: 16px !important;
  color: #081131 !important;

}

.el-menu-item,
.el-sub-menu__title {
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #B0B7C3 !important;
}
.el-input__wrapper {
  position: relative;

  .el-input__inner {
    padding-right: 18px;
  }

  .el-input__suffix {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
  }
}


.el-dialog{
  padding: 0 !important;
}
.el-dialog__header.show-close{
  background: #E8F2FD;
  padding-top: 16px;
  padding-left: 16px;
}
.el-dialog__headerbtn{
  top: 8px !important;
}
.el-dialog__body{
  padding: 16px;
}
.el-dialog__footer{
  padding: 16px;
}
.el-dialog__headerbtn .el-dialog__close{
  font-size: 24px !important;
  color: #333;
}
</style>
