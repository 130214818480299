import router from "./router";
import store from "./store";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import {getAccessToken, removeToken} from "@/utils/auth";

NProgress.configure({showSpinner: false});
const whiteList = ["/login", "/err"];
router.beforeEach((to, from, next) => {
    NProgress.start();
    if (getAccessToken()) {
        if (to.path === "/login") {
            next({path: "/"});
            NProgress.done();
        } else {
            if (!store.state.permission.addRoutes.length) {//[]
                //获取用户信息
                store.dispatch("GetInfo").then((res) => {
                    //拉取用户信息
                    console.log('用户信息',res);
                    store.dispatch("GenerateRoutes").then((accessRoutes) => {
                        if (accessRoutes.length > 0) {
                            accessRoutes.forEach(function (item) {
                                router.addRoute(item); // 动态添加可访问路由表
                            });
                            next({...to, replace: true});
                        } else {
                            removeToken();
                            next(`/login`);
                            NProgress.done();
                        }
                    });
                }).catch((err) => {
                    console.log(err);
                    removeToken();
                    next(`/login`);
                    NProgress.done();
                });
            } else {
                next();
            }
        }
    } else {
        // 没有token
        if (whiteList.indexOf(to.path) !== -1) {
            // 在免登录白名单，直接进入
            next();
        } else {
            // next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
            next(`/login`); // 否则全部重定向到登录页
            NProgress.done();
        }
    }
});

router.afterEach(() => {
    NProgress.done();
});
