import {createRouter, createWebHistory} from "vue-router";
import Layout from "@/layout/index.vue";

const routes = [
    {
        path: "/login",
        name: "login",
        component: () => import(/* webpackChunkName: "login" */ "@/views/login/index.vue"),
    },
    {
        path: "/err",
        name: "err",
        component: () => import(/* webpackChunkName: "err" */ "@/views/error/404.vue"),
    },
    {
        path: "/static",
        component: Layout,
        children: [
            {
                path: "/hotel/add",
                name: "hotelAdd",
                component: () => import(/* webpackChunkName: "hotel" */ "@/views/hotel/add/index.vue"),
            },
            {
                path: "/orders/detail",
                name: "ordersDetail",
                component: () => import(/* webpackChunkName: "orders" */ "@/views/orders/detail.vue"),
            },
            {
                path: "/hotel/logs",
                name: "hotelLogs",
                component: () => import(/* webpackChunkName: "hotel" */ "@/views/hotel/logs/index.vue"),
            }
        ],
    },
];
export const routeConfig = {
    "/orders": {
        children: [
            {
                path: "detail",
                name: "ordersDetail",
                component: () => import(/* webpackChunkName: "orders" */ "@/views/orders/detail.vue"),
                meta: {
                    title: "订单详情",
                    hidden: true,
                },
            },
        ],
    },
    "/hotel": {
        children: [
            {
                path: "add",
                name: "hotelAdd",
                component: () => import(/* webpackChunkName: "hotel" */ "@/views/hotel/add/index.vue"),
                meta: {
                    title: "新增",
                    hidden: true,
                },
            },
            {
                path: "logs",
                name: "hotelLogs",
                component: () => import(/* webpackChunkName: "hotel" */ "@/views/hotel/logs/index.vue"),
                meta: {
                    title: "日志",
                    hidden: true,
                },
            },
        ],
    },
};

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
