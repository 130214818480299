import {login, logout, getInfo} from "@/api/login";
import {getToken, setToken, removeToken} from "@/utils/auth";
const user = {
    state: {
        token: getToken(),
        name: "",
        avatar: "",
        campusId: "",
        usertype: 0, //设置用户类型（0-系统用户，1-项目用户）
        projectId: null, //项目id
        roles: [],
        // permissions: [],
        id: null,
        nickname: "",
    },

    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token;
        },
        SET_NAME: (state, name) => {
            state.name = name;
        },
        SET_CAMPUS: (state, campusId) => {
            state.campusId = campusId;
        },
        SET_AVATAR: (state, avatar) => {
            state.avatar = avatar;
        },
        SET_ROLES: (state, roles) => {
            state.roles = roles;
        },
        // SET_PERMISSIONS: (state, permissions) => {
        //     state.permissions = permissions;
        // },
        SET_USERTYPE: (state, usertype) => {
            //设置用户类型（0-系统用户，1-项目用户）
            state.usertype = usertype;
        },
        SET_PROJECT_ID: (state, projectId) => {
            //设置项目id
            state.projectId = projectId;
        },
        SET_ID: (state, id) => {
            state.id = id;
        },
        SET_NICKNAME: (state, nickname) => {
            state.nickname = nickname;
        },
    },

    actions: {
        // 登录
        Login({commit}, userInfo) {
            const username = userInfo.username.trim();
            const password = userInfo.password;
            const code = userInfo.captchaVerification;
            const uuid = userInfo.uuid;
            return new Promise((resolve, reject) => {
                login(username, password, code, uuid)
                .then((res) => {
                    setToken(res.data.accessToken);
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
            });
        },

        // 获取用户信息
        GetInfo({commit}) {
            return new Promise((resolve, reject) => {
                getInfo()
                .then((res) => {
                    // 没有 data 数据，赋予个默认值
                    if (!res.data) {
                        localStorage.removeItem("Admin-Token");
                        location.href = "/";
                        return;
                    }
                    const user = res.data.data.user;
                    const avatar =
                        user.avatar === "" || user.avatar == null ? require("@/assets/images/eye.png") : user.avatar;
                    if (res.data.data.roles && res.data.data.roles.length > 0) {
                        // 验证返回的roles是否是一个非空数组
                        commit("SET_ROLES", res.data.data.roles);
                        // commit("SET_PERMISSIONS", res.data.data.permissions);
                        localStorage.setItem("permissionsBtn", res.data.data.permissions);
                    } else {
                        commit("SET_ROLES", ["ROLE_DEFAULT"]);
                    }
                    commit("SET_ID", user.id);
                    commit("SET_NAME", user.userName);
                    commit("SET_NICKNAME", user.nickname);
                    commit("SET_AVATAR", avatar);
                    resolve(res.data.data);
                })
                .catch((error) => {
                    reject(error);
                    removeToken();
                    localStorage.removeItem("permissionsBtn");
                });
            });
        },

        // 退出系统
        LogOut({commit, state}) {
            return new Promise((resolve, reject) => {
                logout(state.token)
                .then(() => {
                    commit("SET_TOKEN", "");
                    commit("SET_ROLES", []);
                    commit("SET_PERMISSIONS", []);
                    removeToken();
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
            });
        },
    },
};
export default user;
